import React from 'react'
import Footer from '../footer'

const PrivacyAndPolicy = () => {
    return (
        <div className='w-[400px] min-h-screen flex flex-col justify-center items-left bg-black text-white'>
            <div className='p-4'>
                <div>
                    <p className='text-xl'> Privacy Policy Page</p>
                </div>
                <div className='py-4 flex flex-col gap-4'>
                    <div>
                        <p className='text-xl text-left font-extrabold underline'>Introduction</p>
                        <p className='text-justify pt-2'>Welcome to nofomo! These Terms and Conditions govern your use of our messaging application (the "nofomo"). By downloading, accessing, or using the App, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use the App.
                        </p>
                    </div>
                    <div>
                        <p className='text-xl text-left font-extrabold underline'>Registration</p>
                        <p className='text-justify pt-2'>To use certain features of the App, you must create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.
                        </p>
                    </div>
                    <div>
                        <p className='text-xl text-left font-extrabold underline'>Account Security</p>
                        <p className='text-justify pt-2'>You are responsible for safeguarding your account and for any activities or actions under your account. You agree to notify us immediately of any unauthorized use of your account.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default PrivacyAndPolicy
