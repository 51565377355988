import React from 'react';
import { InstagramLogin } from '@amraneze/react-instagram-login';
import Footer from '../footer';

const LoginPage = () => {

    const AppId = process.env.REACT_APP_INSTAGRAM_APP_ID;
    const redirect_uri = process.env.REACT_APP_REDIRECT_URI;

    const fetchConversations = async (instagramUserId, accessToken) => {
        try {
            const response = await fetch(`https://graph.facebook.com/v13.0/${instagramUserId}/conversations?access_token=${accessToken}`);
            console.log('fetchConversations:', response)
            const data = await response?.json();
            return data;  // Array of conversation objects
        } catch (error) {
            console.error('Error fetching conversations:', error);
            return [];
        }
    };

    const fetchMessages = async (conversationId, accessToken) => {
        try {
            const response = await fetch(`https://graph.facebook.com/v13.0/${conversationId}/messages?access_token=${accessToken}`);
            console.log('fetchMessages:', response)
            const data = await response.json();
            return data?.data;  // Array of message objects
        } catch (error) {
            console.error('Error fetching messages:', error);
            return [];
        }
    };

    const responseInstagram = async (response) => {
        console.log('called', response);
        let userData = null;
        if (response) {
            const fetchData = await fetch(`https://graph.instagram.com/me?fields=id,email&access_token=${response}`)
                .then((res) => userData = res.json())
                .catch((err) => console.log('catch fetchData error:', err))

            console.log('fetchData', fetchData);
            console.log('fetchData :', userData);

            if (userData?.id) {
                // Fetch conversations
                const conversations = await fetchConversations(userData?.id, response);
                console.log('Conversations:', conversations);

                if (conversations && conversations?.length > 0) {
                    // Fetch messages for each conversation
                    conversations?.forEach(async (conversation) => {
                        const messages = await fetchMessages(conversation?.id, response);
                        console.log('Messages:', messages);
                    });
                }
            }
        } else {
            console.log('Login failed');
        }
    };

  
    return (
        <div className='min-h-screen w-[400px] flex flex-col justify-center items-center bg-black text-white '>
            <div className='flex flex-col gap-8'>
                <div className='w-[300px]'>
                    <InstagramLogin
                        clientId={AppId}
                        buttonText="Login with Instagram"
                        redirectUri={redirect_uri}
                        onSuccess={responseInstagram}
                        onFailure={(response) => {
                            console.log('Instagram login failed:', response);
                        }}
                    />
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default LoginPage;
