import React from 'react'
import "./App.css"
import { Chats, LoginPage, PrivacyAndPolicy, TermsAndServices } from './pages'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
const App = () => {
  return (
    <>
      <div className='w-full flex flex-col justify-center items-center'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<LoginPage />} />
            <Route path='/terms-services' element={<TermsAndServices />} />
            <Route path='/privacy-policy' element={<PrivacyAndPolicy />} />
            <Route path='/chats' element={<Chats />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  )
}

export default App
