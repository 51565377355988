import React from 'react'
import Footer from '../footer'

const Chats = () => {
    return (
        <div className='min-h-screen w-[400px] flex flex-col items-center bg-black text-white '>
            <div className='flex flex-col gap-8 pt-8 px-4'>
                <div className="flex items-start p-4 space-x-4 bg-gray-800 rounded-lg mb-4 ">
                    <img className="w-12 h-12 rounded-full object-cover" src="https://images.unsplash.com/photo-1552058544-f2b08422138a?q=80&w=1998&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt='...' />
                    <div className="flex-1 min-w-0">
                        <div className="flex items-center justify-between mb-1">
                            <h3 className="text-white text-lg font-semibold">Courtney Henry</h3>
                            <span className="text-gray-400 text-sm">09:02 AM</span>
                        </div>
                        <p className="text-gray-400">Have you spoken to the delivery man?</p>
                    </div>
                    <img className="w-6 object-cover rounded-xl h-6" src="https://images.unsplash.com/photo-1611162616305-c69b3fa7fbe0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aW5zdGFncmFtfGVufDB8fDB8fHww" alt="logo" />
                </div>
                <div className="flex items-start p-4 space-x-4 bg-gray-800 rounded-lg mb-4 ">
                    <img className="w-12 h-12 rounded-full object-cover" src="https://images.unsplash.com/photo-1500048993953-d23a436266cf?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt='...' />
                    <div className="flex-1 min-w-0">
                        <div className="flex items-center justify-between mb-1">
                            <h3 className="text-white text-lg font-semibold">Will Jane</h3>
                            <span className="text-gray-400 text-sm">01:02 PM</span>
                        </div>
                        <p className="text-gray-400">Feeling grateful for all the support I have..</p>
                    </div>
                    <img className="w-6 object-cover rounded-xl h-6" src="https://images.unsplash.com/photo-1611162616305-c69b3fa7fbe0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aW5zdGFncmFtfGVufDB8fDB8fHww" alt="logo" />
                </div>
                <div className="flex items-start p-4 space-x-4 bg-gray-800 rounded-lg mb-4 ">
                    <img className="w-12 h-12 rounded-full object-cover" src="https://images.unsplash.com/photo-1504593811423-6dd665756598?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt='...' />
                    <div className="flex-1 min-w-0">
                        <div className="flex items-center justify-between mb-1">
                            <h3 className="text-white text-lg font-semibold">butterfly </h3>
                            <span className="text-gray-400 text-sm">09:02 AM</span>
                        </div>
                        <p className="text-gray-400">when will it be ready?</p>
                    </div>
                    <img className="w-6 object-cover rounded-xl h-6" src="https://images.unsplash.com/photo-1611162616305-c69b3fa7fbe0?w=500&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8aW5zdGFncmFtfGVufDB8fDB8fHww" alt="logo" />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Chats
