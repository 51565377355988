import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <>
            <div className='w-[400px] bottom-10 absolute flex justify-evenly items-center border-t border-white pt-4'>
                <Link to={"/terms-services"}><p className='hover:cursor-pointer hover:underline'>Terms And Services</p></Link>
                <Link to={"/privacy-policy"}><p className='hover:cursor-pointer hover:underline'>Privacy Policy</p></Link>
                <Link to={"/"}><p className='hover:cursor-pointer hover:underline'>Home</p></Link>
                <Link to={"/chats"}><p className='hover:cursor-pointer hover:underline'>Chats</p></Link>
            </div>
        </>
    )
}

export default Footer
